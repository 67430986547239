import axios from "axios";
import React, { useEffect, useState } from "react";

import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md";

function BlockComponents({
  showBlock,
  setShowBlock,
  blockLayout,

  blockPhoneLayout,
  rooms,
  floor,
  setRooms,
  selectedUnit,
  setSelectedUnit,
  selectedFloor,
  handleAhedRooms,
  windowWidth,
  blockId,
}) {
  const [pdf, setPdf] = useState("");
  useEffect(() => {
    const getAzhaPdf = async () => {
      const res = await axios.get(
        "https://saroojdevelopment.com/laravel/public/api/community-pdf/azha-apartments"
      );
      setPdf(res?.data.azhapdf);
    };

    getAzhaPdf();
  }, []);
  return (
    showBlock && (
      <div
        onClick={() => {
          setShowBlock(false);
          setSelectedUnit(null);

          setRooms([]);
        }}
        className="z-[200] animate-zoom-in  top-0 left-0 w-full h-full bg-[#000000bb] flex justify-center items-center"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="h-[80%] w-[80%] lg:h-[90%] md:h-[80%] sm:h-[55%] md:w-[100%] lg:w-[95%]  md:flex-col  xl:w-[90%] xl:h-[75%] bg-[white] md:justify-center flex justify-evenly relative"
        >
          <div
            className={` ${
              blockId === "block3"
                ? "h-[100%] md:items-center md:h-[75%]  md:justify-center  lg:pl-[2%] md:pl-[0%] flex justify-center font-[cairo]"
                : "h-[100%] md:items-center md:h-[75%]  lg:pl-[2%] md:pl-[0%] flex justify-center md:justify-end  font-[cairo]"
            } `}
          >
            <img
              className={`${
                blockId === "block3"
                  ? "relative w-[100%] lg:w-[80%] h-full"
                  : "relative h-full md:hidden"
              }`}
              alt="Block"
              src={blockLayout}
            />
            {(blockId === "block1" || blockId === "block2") && (
              <img
                className="relative h-full md:flex md:w-[100vw] hidden"
                alt="Block Phone"
                src={blockPhoneLayout}
              />
            )}
            <>
              {rooms?.map((data, index) => (
                <div
                  key={index}
                  className="xl:text-[1.1vw] lg:text-[8.5px] sm:text-[7px] text-[13px] justify-center items-center flex flex-col gap-[5px] absolute text-center"
                  style={{
                    ...(windowWidth >= 600 && {
                      top: data.top,
                      bottom: data.bottom,
                      left: data.left,
                      right: data.right,
                      height: data.height,
                      width: data.width,
                      backgroundColor:
                        selectedUnit === index
                          ? data.status === "BOOKED"
                            ? "#ff0000a9"
                            : "#008000b4"
                          : "", // fallback when not selected
                    }),
                    ...(windowWidth <= 600 && {
                      top: data.topXl,
                      left: data.leftXl,
                      height: data.heightXl,
                      width: data.widthXl,
                      backgroundColor:
                        selectedUnit === index
                          ? data.status === "BOOKED"
                            ? "#b6b60eb0" // Yellowish for BOOKED when selected
                            : data.status === "SOLD"
                            ? "#ff0000c5" // Red for SOLD when selected
                            : "#008000b9" // Green for AVAILABLE or other statuses when selected
                          : "", // fallback when not selected
                    }),
                  }}
                  onClick={() => setSelectedUnit(index)}
                >
                  <p
                    className="border w-[fit-content] px-[5%] border-[black]"
                    style={{ backgroundColor: data.bgColor }}
                  >
                    {data.unit}
                  </p>
                  <p>{data.room}</p>
                </div>
              ))}
            </>
          </div>

          <div className="flex mt-[50px] md:flex-col lg:mt-[1%] h-[fit-content] md:h-[100px] sm:h-[80px] md:gap-[10px] md:justify-start md:items-start md:w-[100%]  flex-col gap-[5px] rounded-[10px] md:rounded-[5px] md:px-[1%] w-[80px] py-[0.5%] font-[cairo] bg-[#2c2c2c] text-white justify-center items-center">
            <p className="text-center w-[100%]">Floors</p>
            <div className="flex flex-col justify-center items-center w-[100%] md:flex-row">
              <div className="bg-[#222222] rounded-t-[20px] md:rotate-[-90deg] md:w-[50px] text-white w-[90%] h-[fit-content] flex justify-center items-center text-center cursor-not-allowed">
                <MdOutlineArrowDropUp className="text-[30px]" />
              </div>
              <div className="flex flex-col md:flex-row gap-[10px] justify-evenly py-[10%] md:py-[0%] transition-all duration-500 ease-in-out">
                {floor?.map((floors) => (
                  <p
                    key={floors.id}
                    className={`h-[50px] rounded-[5px] cursor-pointer w-[50px] sm:w-[30px] sm:h-[30px] border text-center flex justify-center items-center  ${
                      selectedFloor === floors.id ? "bg-[gray]" : ""
                    } hover:bg-[gray] transition-all duration-500 ease-in-out`}
                    onClick={() => handleAhedRooms(floors.id)}
                  >
                    {floors.floor}
                  </p>
                ))}
              </div>
              <div className="bg-[#222222] rounded-b-[20px] text-white w-[90%] md:rotate-[-90deg] md:w-[50px] text-center flex justify-center items-center text-[50px] cursor-not-allowed">
                <MdOutlineArrowDropDown className="text-[30px]" />
              </div>
            </div>
          </div>
          {blockId === "block1" && (
            <div className="md:text-[12px] flex justify-end items-end  gap-[2%] lg:flex-col lg:items-center md:text-end px-[1.5%] absolute w-[100%] top-[10px] lg:justify-center md:justify-end">
              <div className="animate-bounce md:my-[2%] w-[100%] lg:text-center md:text-end text-end ">
                <a
                  href={`https://saroojdevelopment.com/laravel/public/api/storage/${pdf}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-[fit-content] font-bold text-[18px] lg:text-[12px]  hover:text-[gray] hover:underline cursor-pointer"
                >
                  Download Our brochure
                </a>
              </div>
              <div className="flex gap-[5px] justify-end md:w-[100%]">
                <p className="p-[5px] md:p-[1%] rounded-[5px] bg-[#008000b4]">
                  Available
                </p>
                <p className="p-[5px] md:p-[1%] rounded-[5px] bg-[#b6b60eb0]">
                  Booked
                </p>
                <p className="p-[5px] md:p-[1%] rounded-[5px] bg-[#ff0000a9]">
                  Sold
                </p>
              </div>
            </div>
          )}
          {blockId === "block2" && (
            <div className=" md:text-[12px] flex justify-end items-end lg:justify-center  gap-[2%] lg:items-center md:text-end px-[1.5%] absolute w-[100%] top-[10px] md:justify-end">
              <div className="animate-bounce md:my-[1%]  md:text-end text-end ">
                <a
                  href={`https://saroojdevelopment.com/laravel/public/api/storage/${pdf}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-[fit-content] font-bold text-[18px] lg:text-[12px]  hover:text-[gray] hover:underline cursor-pointer"
                >
                  Download Our brochure
                </a>
              </div>
              <div className="flex gap-[5px]  justify-end ">
                <p className="p-[5px] md:p-[1%] rounded-[5px] bg-[#008000b4]">
                  Available
                </p>
                <p className="p-[5px] md:p-[1%] rounded-[5px] bg-[#b6b60eb0]">
                  Booked
                </p>
                <p className="p-[5px] md:p-[1%] rounded-[5px] bg-[#ff0000a9]">
                  Sold
                </p>
              </div>
            </div>
          )}

          {blockId === "block3" && (
            <div className=" md:text-[12px] flex  gap-[2%] flex-col py-[2%] justify-start  px-[1.5%] absolute top-[10px] left-[1px] lg:justify-center md:justify-end">
              <div className="animate-bounce md:my-[2%] ">
                <a
                  href={`https://saroojdevelopment.com/laravel/public/api/storage/${pdf}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-[fit-content] font-bold text-[18px] lg:text-[12px]  hover:text-[gray] hover:underline cursor-pointer"
                >
                  Download Our brochure
                </a>
              </div>
              <div className=" md:text-[12px] flex  gap-[5px] top-[10px]">
                <p className="p-[5px] md:p-[1%] rounded-[5px] bg-[#008000b4]">
                  Available
                </p>
                <p className="p-[5px] md:p-[1%] rounded-[5px] bg-[#b6b60eb0]">
                  Booked
                </p>
                <p className="p-[5px] md:p-[1%] rounded-[5px] bg-[#ff0000a9]">
                  Sold
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
}

export default BlockComponents;
