import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "./swiper.css";
import { FreeMode, Pagination, Autoplay, Navigation } from "swiper/modules";
import { Link, useParams } from "react-router";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import axios from "axios";
import saroojicon from "../../images/map/sarooj-icon.png";

function Community() {
  const { link } = useParams();
  const [community, setCommunity] = useState({});
  const [communityButtons, setCommunityButtons] = useState([]);
  const [showSlider, setShowSlider] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [sliderImages, setSliderImages] = useState([]);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowSlider(false);
      }
    };

    if (showSlider) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSlider]);

  const fetchCommunityById = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `https://saroojdevelopment.com/laravel/public/api/fetch-single-community/${link}`
      );
      const communityData = res?.data?.community;
      setCommunity(communityData);
      setCommunityButtons(communityData?.community_buttons);

      if (communityData?.images.length > 0) {
        setSelectedImage(communityData?.images[0].imageUrl);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching community data:", error);
    }
  };

  useEffect(() => {
    fetchCommunityById();
  }, [link]);

  const handleButtonClick = (buttonImages) => {
    setSliderImages(buttonImages);
    setShowSlider(true);
  };

  const currentLanguageCode = cookies.get("i18next") || "en";

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-[100vh] w-[100%] bg-[#0000007e]">
          <div className="w-10 h-10 border-4 border-t-blue-500 border-gray-300 rounded-full animate-spin"></div>
        </div>
      ) : (
        <div className="relative">
          {showSlider && (
            <div className="z-[200] animate-zoom-in fixed top-0 left-0 w-full h-full bg-[#000000bb] flex justify-center items-center">
              <div
                className="w-[50%] h-[90%] lg:w-[100%] xl:h-[60%]"
                ref={modalRef}
              >
                <Swiper
                  navigation={true}
                  modules={[Navigation]}
                  centeredSlides={true}
                  slidesPerView={1}
                  loop={true}
                  className="mySwiper"
                >
                  {sliderImages?.map((btnImage, index) => (
                    <SwiperSlide key={index}>
                      <img
                        alt=""
                        className="images-villa"
                        src={`https://saroojdevelopment.com/laravel/public/api/storage/${btnImage.btnImages}`}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          )}
          <div key={community?.id}>
            <div className="h-[80vh] bg-[gray] lg:h-[auto] relative">
              <video
                className="relative w-full h-full lg:h-[80vh] md:h-[60vh] lg:mt-[5%] object-cover z-0"
                autoPlay
                muted
                loop
              >
                <source
                  src={`https://saroojdevelopment.com/laravel/public/api/storage/${community?.video}`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>

            <div
              className={`w-[100%]  lg:flex-col flex p-[5%] ${
                currentLanguageCode === "ar" && "text-right"
              }`}
              dir={currentLanguageCode === "ar" ? "rtl" : "ltr"}
            >
              <div className="w-[50%] flex flex-col justify-between lg:w-[100%] p-[3%]">
                <h1 className="text-[2.5vw] lg:text-[30px] sm:text-[25px] font-[cairo]">
                  {currentLanguageCode === "ar"
                    ? community.title_ar
                    : community.title}
                </h1>

                <div
                  className={`text-[18px] flex flex-col gap-[10px] py-[3%] ${
                    currentLanguageCode === "ar" ? "text-right" : "text-justify"
                  }`}
                  dangerouslySetInnerHTML={{
                    __html:
                      currentLanguageCode === "ar"
                        ? community?.description_ar
                        : community?.description,
                  }}
                />
                <>
                  <div className="animate-bounce md:my-[5%] ">
                    <a
                      href={`https://saroojdevelopment.com/laravel/public/api/storage/${community?.pdf}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mb-[2%] w-[fit-content] font-bold text-[18px]  hover:text-[gray] hover:underline cursor-pointer"
                    >
                      {t("navbar.community.brochure")}
                    </a>
                  </div>
                  <div
                    className={` mt-[5%] ${
                      currentLanguageCode === "ar" &&
                      "justify-end "
                    } flex justify-between xl:justify-between lg:items-center lg:flex-col lg:gap-[15px]  xl:text-[12px] `}
                  >
                    {communityButtons?.map((item) => (
                      <div key={item?.id} className="w-[100%]">
                        <button
                          onClick={() => handleButtonClick(item?.btn_images)}
                          className="btn w-[10vw] xl:w-[12vw] lg:w-[80vw]  py-[5%] xl:text-[1.1vw] lg:text-[12px] lg:p-[10px] text-[12px]"
                        >
                          {currentLanguageCode === "ar"
                            ? item?.btnName_ar
                            : item?.btnName}
                        </button>
                      </div>
                    ))}
                  </div>
                </>
              </div>

              <div className="w-[50%] flex justify-center items-center lg:w-[100%]">
                <div className="h-[100%] lg:w-[100%] p-[3%]">
                  <div className="w-[40vw] lg:w-[100%]">
                    <div className="h-[40vh] md:hidden pb-[2%]">
                      <img
                        alt="Selected"
                        src={`https://saroojdevelopment.com/laravel/public/api/storage/${selectedImage}`}
                        className="w-[100%] bg-[gray] object-cover h-[100%]"
                      />
                    </div>

                    <div className="h-[200px]">
                      <Swiper
                        loop={true}
                        navigation={true}
                        breakpoints={{
                          1280: {
                            slidesPerView: 3,
                          },
                          600: {
                            slidesPerView: 2,
                          },
                        }}
                        spaceBetween={30}
                        freeMode={true}
                        autoplay={{
                          delay: 2500,
                          disableOnInteraction: true,
                        }}
                        modules={[FreeMode, Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                      >
                        {community?.images?.map((image, index) => (
                          <SwiperSlide key={index}>
                            <img
                              onClick={() => setSelectedImage(image?.imageUrl)}
                              alt={image.id}
                              src={`https://saroojdevelopment.com/laravel/public/api/storage/${image?.imageUrl}`}
                              className="w-[100%] bg-[gray] h-[100%] object-cover"
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Community;
