import React, { useEffect, useState } from "react";
import logo from "../images/sarooj-white-logo.png";
import { Link, useLocation, useParams } from "react-router";
import { FaBars } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import { LuMapPin } from "react-icons/lu";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import i18next from "i18next";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import axios from "axios";
function Navbar() {
  const [showHospitalityHover, setShowHospitalityHover] = useState(false);
  const [openCommunity, setOpenCommunity] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const { language } = useParams();
  const [menuOpen, setMenuOpen] = useState(false);
  const [communityPages, setCommunityPages] = useState([]);
  const isActive = (path) => location.pathname === path;

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const currentLanguageCode = cookies.get("i18next") || "en";

  const fetchCommunitiesVisible = async () => {
    try {
      const res = await axios.get(
        "https://saroojdevelopment.com/laravel/public/api/fetch-community"
      );
      setCommunityPages(res?.data?.community);
    } catch (error) {
      console.error("Error fetching communities:", error);
      // You can display an error message to the user, e.g., using toast or alert
      alert("Failed to fetch communities. Please try again later.");
    }
  };

  useEffect(() => {
    fetchCommunitiesVisible();
  }, []);

  return (
    <div className="z-[100] absolute w-[100%] font-[cairo]">
      <div
        className={`flex lg:hidden  justify-between px-[5%] xl:px-[2%] items-center py-[1%]  bg-[#202020c2] ${
          currentLanguageCode === "ar" && "flex-row-reverse"
        }`}
      >
        <Link to="/" className=" xl:w-[180px] w-[250px] h-[100%]">
          <img alt="" className="w-[100%] h-[100%] object-contain" src={logo} />
        </Link>
        <div
          className={`w-[70%] xl:w-[75%]  flex justify-evenly items-center h-[100%] mt-[0.5%] ${
            currentLanguageCode === "ar" && "flex-row-reverse"
          }`}
        >
          <Link
            to="/"
            className={`${
              isActive("/") ? "bg-[gray] text-[white]" : "text-white"
            } relative cursor-pointer font-[400]  hover:bg-[gray] hover:text-[white] w-[200px] xl:w-[140px] 2xl:w-[180px] xl:text-[14px]  text-center p-[1%] `}
          >
            {t("navbar.home")}
          </Link>

          <div
            onMouseOver={() => setShowHospitalityHover(true)}
            onMouseLeave={() => setShowHospitalityHover(false)}
            className={`cursor-pointer ${
              isActive("/our-community") || showHospitalityHover
                ? "text-[white]  bg-[gray]"
                : "text-white"
            } relative cursor-pointer w-[200px] xl:w-[140px] 2xl:w-[180px] xl:text-[14px]  text-center p-[1%]  text-white font-[400]  hover:bg-[gray]`}
          >
            <Link
              to="/our-community"
              className={`cursor-pointer ${
                isActive("/our-community") || showHospitalityHover
                  ? "text-[white]  bg-[gray]"
                  : "text-white"
              } font-[400] hover:text-[white]`}
            >
              {t("navbar.community.ourCommunity")}
            </Link>
            {showHospitalityHover && (
              <div
                className="bg-[gray] mt-[1px] text-white absolute top-[45px] z-[500] left-0 w-[100%] p-[1%] flex flex-col  py-[2%]"
                style={{
                  opacity: showHospitalityHover ? 1 : 0,
                  transform: showHospitalityHover
                    ? "translateY(0)"
                    : "translateY(-100%)",
                }}
              >
                {communityPages?.map((item) => (
                  <>
                    <Link
                      key={item.id}
                      className={`py-[5%] px-[1%] border-t-[0.1px] border-y-[white] ${
                        item.visible === 1
                          ? "hover:text-[black] cursor-pointer"
                          : "cursor-default"
                      }`}
                      to={item.visible === 1 && `/our-community/${item.link}`}
                    >
                      {currentLanguageCode === "ar" ? item.name_ar : item.name}
                    </Link>
                  </>
                ))}
              </div>
            )}
          </div>

          <Link
            to="/contact-us"
            className={`${
              isActive("/contact-us") ? "bg-[gray] text-[white]" : "text-white "
            } relative cursor-pointer font-[400]  hover:bg-[gray] hover:text-[white] w-[200px] xl:w-[140px] 2xl:w-[180px] xl:text-[14px]  text-center p-[1%] `}
          >
            {t("navbar.contact-us")}
          </Link>

          <Link
            to="/coming-soon"
            className={`${
              isActive("/coming-soon") ? "bg-[gray] text-[white]" : "text-white"
            } relative cursor-pointer  font-[400]  hover:bg-[gray] hover:text-[white] w-[200px] xl:w-[140px] 2xl:w-[180px] xl:text-[14px]  text-center p-[1%] `}
          >
            {t("navbar.news-events")}
          </Link>

          <Link
            to="/contact-us"
            className=" hover:text-[white] w-[50px] h-[50px] xl:h-[30px] xl:w-[30px] flex justify-center items-center relative cursor-pointer text-white font-[400]  hover:bg-[gray] text-center p-[1%] rounded-full"
          >
            <LuMapPin className="w-[50px] " />
          </Link>
          <div className="flex gap-4 text-white">
            <button
              onClick={() => {
                i18next.changeLanguage("en");
                window.location.reload();
              }}
            >
              EN
            </button>
            <span>/</span>
            <button
              onClick={() => {
                i18next.changeLanguage("ar");
                window.location.reload();
              }}
            >
              العربية
            </button>
          </div>
        </div>
      </div>

      <div className="lg:flex hidden flex-col bg-bg-main shadow-sm bg-[#202020c2] ">
        <div
          className={`bg-[white] px-[4%] flex  items-center gap-[10px] text-[gray]  ${
            currentLanguageCode === "ar" ? "justify-start" : "justify-end"
          }`}
        >
          <div className=" hover:text-[white] w-[50px] h-[50px] xl:h-[30px] xl:w-[30px] flex justify-center items-center relative cursor-pointer text-white font-[400]  hover:bg-[gray] text-center p-[1%] rounded-full">
            <LuMapPin className="w-[50px]  " />
          </div>

          <div className="gap-[5px] xl:text-[14px] flex">
            <button
              onClick={() => {
                i18next.changeLanguage("en");
                window.location.reload(); // Refresh the page after changing the language
              }}
            >
              EN
            </button>{" "}
            <span>/</span>
            <button
              onClick={() => {
                i18next.changeLanguage("ar");
                window.location.reload(); // Refresh the page after changing the language
              }}
            >
              العربية
            </button>
          </div>
        </div>
        <div className="flex justify-between items-center px-[5%]  p-4 ">
          <div className=" h-[70px] w-[200px] flex justify-center items-center lg:w-[150px] z-[100] ">
            <Link to="/">
              <img src={logo} className="" alt="Logo" />
            </Link>
          </div>

          <div className="hidden lg:flex items-center">
            {menuOpen ? (
              <MdOutlineClose
                onClick={toggleMenu}
                style={{ fontSize: 25, color: "white", zIndex: "500" }}
              />
            ) : (
              <FaBars
                onClick={toggleMenu}
                style={{ fontSize: 25, color: "white", zIndex: "500" }}
              />
            )}
          </div>
        </div>
        <div
          className={`fixed inset-0 bg-[#2c2c2c] transform ${
            menuOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300 ease-in-out bg-[#2c2c2c] z-50`}
        >
          <div className="flex flex-col items-center justify-center h-full gap-10 p-5">
            <div className="flex text-[20px] flex-col items-center ">
              <Link
                onClick={toggleMenu}
                to="/"
                className={`cursor-pointer ${
                  isActive("/") ? "text-[white] bg-[gray]" : "text-white"
                } relative cursor-pointer font-[400]  w-[150px] text-[18px] text-center p-[5%] `}
              >
                {t("navbar.home")}
              </Link>
              <div
                className={`${
                  isActive("/our-community")
                    ? "text-[white] bg-[gray]"
                    : "text-white"
                } relative justify-between flex-col font-[400] flex w-[200px] text-[18px] p-[5%]  cursor-pointer`}
              >
                <div
                  className={`flex justify-between items-center ${
                    currentLanguageCode === "ar" && "flex-row-reverse"
                  }`}
                >
                  <Link onClick={toggleMenu} to="/our-community">
                    {t("navbar.community.ourCommunity")}
                  </Link>
                  <div className="w-[30px] h-[30px] flex justify-center items-center">
                    {openCommunity ? (
                      <IoIosArrowUp onClick={() => setOpenCommunity(false)} />
                    ) : (
                      <IoIosArrowDown onClick={() => setOpenCommunity(true)} />
                    )}
                  </div>
                </div>
              </div>

              {openCommunity && (
                <div className="text-[13px] flex flex-col gap-[10px] items-center justify-center">
                  {communityPages?.map((item) => (
                    <Link
                      onClick={toggleMenu}
                      className="p-[5%] w-[200px]  text-center text-white"
                      to={item.visible === 1 && `/our-community/${item.link}`}
                    >
                      {currentLanguageCode === "ar" ? item.name_ar : item.name}
                    </Link>
                  ))}
                </div>
              )}

              <Link
                onClick={toggleMenu}
                to="/coming-soon"
                className={`cursor-pointer ${
                  isActive("/coming-soon")
                    ? "text-[white] bg-[gray]"
                    : "text-white"
                } relative cursor-pointer  font-[400] text-[18px]  w-[200px] text-center p-[5%] `}
              >
                {t("navbar.news-events")}
              </Link>

              <Link
                onClick={toggleMenu}
                to="/contact-us"
                className={`cursor-pointer ${
                  isActive("/contact-us")
                    ? "text-[white] bg-[gray]"
                    : "text-white"
                } relative cursor-pointer font-[400]  w-[150px] text-[18px] text-center p-[5%] `}
              >
                {t("navbar.contact-us")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
