import axios from "axios";

const API_BASE_URL = "https://saroojdevelopment.com/laravel/public/api";

export const fetchHomeVideo = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/home-info`);
    const videoData = response.data;
    return videoData?.message; // Return the message data directly
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

export const fetchSingleCommunity = async (link) => {
  const cachedCommunity = localStorage.getItem(`community_${link}`);

  if (cachedCommunity) {
    return JSON.parse(cachedCommunity);
  }
  const res = await axios.get(
    `https://saroojdevelopment.com/laravel/public/api/fetch-single-community/${link}`
  );
  const communityData = res.data.community;
  localStorage.setItem(`community_${link}`, JSON.stringify(communityData));

  return communityData;
};
