import React, { useEffect, useState } from "react";
import "./ourCommunity.css";
import { Link } from "react-router";
import { useTranslation } from "react-i18next";
import axios from "axios";
import i18next from "i18next";
import cookies from "js-cookie";

function OurCommunity() {
  const [communityPages, setCommunityPages] = useState([]);
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const fetchCommunitiesVisible = async () => {
    try {
      const res = await axios.get(
        "https://saroojdevelopment.com/laravel/public/api/fetch-community"
      );
      setCommunityPages(res?.data?.community);
    } catch (error) {
      console.error("Error fetching communities:", error);
      // You can display an error message to the user, e.g., using toast or alert
      alert("Failed to fetch communities. Please try again later.");
    }
  };

  useEffect(() => {
    fetchCommunitiesVisible();
  }, []);

  return (
    <div className="card h-[100vh] text-[50px] 2xl:text-[40px] lg:text-[25px] md:text-[18px] text-end lg:h-[70vh] md:h-[50vh] font-[cairo]">
      {communityPages?.map((item) => (
        <Link
          to={item.visible === 1 && `/our-community/${item.link}`}
          className="card-item image"
          style={{
            backgroundImage: `url(https://saroojdevelopment.com/laravel/public/api/storage/${item.mainImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            cursor: item.visible === 1 ? "pointer" : "default",
          }}
        >
          <span className="card-text lg:right-[60px] right-[100px] w-[55vw] 2xl:w-[75vw] xl:w-[85vw] md:w-[90vw] py-[0.5em] pr-[50%] sm:w-[120vw]">
            {currentLanguageCode === "ar" ? item.name_ar : item.name}
          </span>
        </Link>
      ))}
    </div>
  );
}

export default OurCommunity;
