import React from "react";
import { useQuery } from "@tanstack/react-query";
import LazyLoad from "react-lazyload";
import { IoIosArrowDown } from "react-icons/io";
import { fetchHomeVideo } from "../../api";
import saroojicon from "../../images/map/sarooj-icon.png";

function Video({ scrollToCommunity }) {
  const {
    data: fetchVideo,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["fetchHomeVideo"],
    queryFn: () => fetchHomeVideo(),
    staleTime: 1000 * 60 * 60 * 24, // 24 hours (adjust as needed)
    cacheTime: 1000 * 60 * 60 * 24, // 24 hours (optional, prevents cache from being garbage collected)
  });

  if (error) return <div>Error fetching video</div>;

  return (
    <div className="relative w-[100%] h-[100%] font-[cairo]">
      {isLoading && (
        <div className="relative flex flex-col justify-center items-center w-full h-[95vh] lg:h-[80vh] md:h-[60vh] lg:mt-[5%] object-cover z-0">
          <img className="w-[100px] animate-bounce" src={saroojicon} alt="" />
          <p className="mt-[-10px]">Loading...</p>
        </div>
      )}
      {fetchVideo?.map((vid) => (
        <LazyLoad key={vid.id} height={200} offset={100} once>
          <video
            className="relative w-full h-[95vh] lg:h-[80vh] md:h-[60vh] lg:mt-[5%] object-cover z-0"
            autoPlay
            muted
            loop
          >
            <source
              src={`https://saroojdevelopment.com/laravel/public/api/storage/${vid.homeVideo}`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </LazyLoad>
      ))}

      <div className="absolute inset-0 bg-[#00000057] z-10"></div>

      <div className="w-[100%] text-[80px] absolute top-[85%] font-[100] text-white flex justify-center items-center z-20">
        <IoIosArrowDown
          onClick={scrollToCommunity}
          className="cursor-pointer bounce"
        />
      </div>
    </div>
  );
}

export default Video;
