import React, { useEffect, useRef } from "react";
import Video from "../Components/Home/Video";
import OurCommunity from "../Components/Home/OurCommunity/OurCommunity";
import { useTranslation } from "react-i18next";
function Home() {
  const communityRef = useRef(null);
  const { t } = useTranslation();
  const scrollToCommunity = () => {
    if (communityRef.current) {
      communityRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Video scrollToCommunity={scrollToCommunity} />

      <div ref={communityRef}>
        <OurCommunity />
      </div>
    </div>
  );
}

export default Home;
