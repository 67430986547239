import landingPage from "../../images/map/background-main.webp";
import phonePage from "../../images/map/map-phone.webp";
import { useState, useEffect } from "react";
import block1Phone from "../../images/map/block1-phone.png";
import cookies from "js-cookie";
import "swiper/css";
import "swiper/css/navigation";
import commercialPin from "../../images/map/icons/business_center.webp";
import townHousePin from "../../images/map/icons/ahed-villas-white.webp";
import thurayaPin_ar from "../../images/map/icons/thuraya_ar.png";
import ahed_ar from "../../images/map/icons/ahed_ar.png";
import azha1_ar from "../../images/map/icons/azha1_ar.png";
import azha2_ar from "../../images/map/icons/azha2_ar.png";
import azha3_ar from "../../images/map/icons/azha3_ar.png";
import thurayaPin from "../../images/map/icons/althuraya-villas-white.webp";
import townZoomIn from "../../images/map/town-zoom.webp";
import blockPin1 from "../../images/map/icons/azha-apartment-white-1.webp";
import blockPin2 from "../../images/map/icons/azha-apartment-white-2.webp";
import blockPin3 from "../../images/map/icons/azha-apartment-white-3.webp";
import block1layout from "../../images/map/block1-typical-layout-B&W.webp";
import block2layout from "../../images/map/block2-typical-layout-B&W.webp";
import block3layout from "../../images/map/block3-typical-layout-B&W.png";
import bedroom from "../../images/map/icons/bedroon-icon.webp";
import house from "../../images/map/icons/home-icon.webp";
import plotSize from "../../images/map/icons/plot-size-icon.png";
import gfaTotal from "../../images/map/icons/total-gfa-icon.png";
import gfa from "../../images/map/icons/gfa-icon.webp";
import plazaPin from "../../images/map/icons/plaza_pin.png";
import block4Pin from "../../images/map/icons/azha_block_4.webp";
import pin from "../../images/map/icons/pin.webp";
import plaza_ar from "../../images/map/icons/plaza_ar.png";
import azha4_ar from "../../images/map/icons/azha4_ar.png";
import business_ar from "../../images/map/icons/businessCenter_ar.png";
import axios from "axios";
import BlockComponents from "./BlockComponents.jsx";
import thurayaIn from "../../images/map/Thuraya top view.webp";
import thurayaPhone from "../../images/map/thuraya-phone-view.webp";
function CommunityMap() {
  const [showTownHouses, setShowTownHouses] = useState(false);
  const [showResidential, setShowResidential] = useState(false);
  const [showVillas, setShowVillas] = useState(false);
  const [townZoom, setTownZoom] = useState(false);
  const [showBlock1, setShowBlock1] = useState(false);
  const [showBlock2, setShowBlock2] = useState(false);
  const [showBlock3, setShowBlock3] = useState(false);
  const [hoveredVilla, setHoveredVilla] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [floor, setFloor] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  const [error, setError] = useState(null);
  const [openPlaza, setOpenPlaza] = useState(false);
  const [openThuraya, setOpenThuraya] = useState(false);
  const [showLocations, setShowLocations] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(false);
  const [interactiveMap, setInteractiveMap] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);
  const [thurayaMap, setThurayaMap] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const currentLanguageCode = cookies.get("i18next") || "en";
  const [pdf, setPdf] = useState("");
  useEffect(() => {
    const getAhedPdf = async () => {
      const res = await axios.get(
        "https://saroojdevelopment.com/laravel/public/api/community-pdf/ahed-villas"
      );
      setPdf(res?.data.azhapdf);
    };

    getAhedPdf();
  }, []);

  const handleGlobalClick = () => {
    if (showVillas) setShowVillas(false);
    if (showTownHouses) setShowTownHouses(false);
    if (showResidential) setShowResidential(false);
    if (townZoom) setTownZoom(false);
    if (openPlaza) setOpenPlaza(false);
    if (openThuraya) setOpenThuraya(false);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const closeModal = () => {
    setShowVideoModal(false);
    setVideoSrc("");
  };

  const pinData = [
    {
      id: 1,
      alt: "Business Center",
      onClick: () => handleImageClick(9),
      src: currentLanguageCode === "ar" ? business_ar : commercialPin,
      classes:
        currentLanguageCode === "ar"
          ? "w-[60px] right-[6%] xl:w-[50px]"
          : "w-[59px] xl:w-[50px] right-[6%] lg:left-[6%]",
      top: "top-[57%]",
    },

    {
      id: 3,
      alt: "Plaza",
      onClick: () => handleImageClick(8),
      src: currentLanguageCode === "ar" ? plaza_ar : plazaPin,
      classes:
        currentLanguageCode === "ar"
          ? "w-[60px] right-[61%] xl:w-[50px]"
          : "w-[59px] xl:w-[50px] right-[61%]",
      top: "top-[35%]",
    },
  ];

  const handleImageClick = async (id) => {
    try {
      const response = await axios.get(
        `https://saroojdevelopment.com/laravel/public/api/video-interactive-map/${id}`
      );
      const { video } = response?.data;

      setVideoSrc(video);
      setShowVideoModal(true);
    } catch (err) {
      console.error("Error fetching video:", err);
      setError("Unable to load video");
    }
  };

  const handleAhedClicks = async (id) => {
    try {
      const response = await axios.get(
        `https://saroojdevelopment.com/laravel/public/api/azha-apartments-interactive-map/${id}`
      );
      const res = response.data.floors;

      setFloor(res);
    } catch (err) {
      console.error("Error fetching video:", err);
      setError("Unable to load video");
    }
  };
  const handleAhedRooms = async (id) => {
    try {
      const response = await axios.get(
        `https://saroojdevelopment.com/laravel/public/api/azha-apartments-rooms/${id}`
      );
      const res = response?.data.rooms;
      setRooms(res);
      setSelectedFloor(id);
    } catch (err) {
      console.error("Error fetching video:", err);
      setError("Unable to load video");
    }
  };

  useEffect(() => {
    const fetchAhedVilla = async () => {
      const res = await axios.get(
        "https://saroojdevelopment.com/laravel/public/api/ahed-interactive-map-status"
      );

      setInteractiveMap(res?.data?.ahedVillas);
    };
    fetchAhedVilla();
  }, []);

  useEffect(() => {
    const fetchThurayaData = async () => {
      const res = await axios.get(
        "https://saroojdevelopment.com/laravel/public/api/thuraya-interactive-map"
      );

      setThurayaMap(res?.data?.thurayaMap);
    };
    fetchThurayaData();
  }, []);

  return (
    <div className="w-[100%] overflow-hidden  relative">
      <div className="  flex justify-center items-center h-[100vh]  xl:h-[85vh] lg:h-[55vh] md:h-[80vh] sm:h-[75vh] relative">
        <div
          className="absolute inset-0"
          onClick={() => {
            handleGlobalClick();
          }}
        >
          <img
            className="w-full h-[100%] md:hidden relative  "
            src={landingPage}
            alt="Sarooj landing page"
          />{" "}
          <img
            className="w-full hidden md:flex object-cover relative h-[100%]  "
            src={phonePage}
            alt="Sarooj landing page"
          />
        </div>
        <img
          alt=""
          onClick={(e) => setTownZoom(true)}
          src={currentLanguageCode === "ar" ? ahed_ar : townHousePin}
          className={` ${
            currentLanguageCode === "ar"
              ? "w-[60px] left-[18%] xl:w-[50px]"
              : "w-[100px] xl:w-[80px] left-[16%]"
          }  lg:hidden cursor-pointer bounce   top-[24%]   absolute`}
        />

        <img
          alt=""
          onClick={(e) => setOpenThuraya(true)}
          src={currentLanguageCode === "ar" ? thurayaPin_ar : thurayaPin}
          className={` ${
            currentLanguageCode === "ar"
              ? "w-[60px] right-[63%] xl:w-[50px]"
              : "w-[100px] xl:w-[80px] right-[63%]"
          }  lg:hidden cursor-pointer bounce  top-[52%]  absolute`}
        />
        <img
          onClick={() => {
            handleAhedClicks(4);
            setShowBlock1(true);
          }}
          className={` ${
            currentLanguageCode === "ar"
              ? "w-[60px] left-[42%] xl:w-[50px]"
              : "w-[120px] xl:w-[100px] left-[41%] xl:left-[40%]"
          } cursor-pointer lg:hidden   top-[35%]  bounce absolute`}
          alt=""
          src={currentLanguageCode === "ar" ? azha1_ar : blockPin1}
        />
        <img
          onClick={() => {
            handleAhedClicks(5);
            setShowBlock2(true);
          }}
          className={` ${
            currentLanguageCode === "ar"
              ? "w-[60px] left-[49%] xl:w-[50px]"
              : "w-[120px] xl:w-[100px] xl:left-[46%] left-[47%]"
          } cursor-pointer lg:hidden   top-[39%]   bounce absolute`}
          alt=""
          src={currentLanguageCode === "ar" ? azha2_ar : blockPin2}
        />
        <img
          onClick={() => {
            handleAhedClicks(6);
            setShowBlock3(true);
          }}
          className={` ${
            currentLanguageCode === "ar"
              ? "w-[60px] left-[56%] xl:w-[50px]"
              : "w-[120px] xl:w-[100px] left-[55%] xl:left-[53%]"
          }  cursor-pointer  lg:hidden  top-[40%]  bounce absolute`}
          alt=""
          src={currentLanguageCode === "ar" ? azha3_ar : blockPin3}
        />
        {showVideoModal && (
          <div
            onClick={closeModal}
            className="z-[200] animate-zoom-in top-0 left-0 w-full h-full bg-[#000000bb] flex justify-center items-center"
          >
            <div>
              <div className="h-[80vh] relative flex justify-center items-center">
                {error ? (
                  <div className="text-white">Error: {error}</div>
                ) : (
                  <video
                    className="relative w-[50%] lg:w-[80%]  object-cover z-0"
                    controls
                    onClick={(e) => e.stopPropagation()}
                  >
                    <source
                      src={`https://saroojdevelopment.com/laravel/public/api/storage/${videoSrc}`}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            </div>
          </div>
        )}

        <BlockComponents
          showBlock={showBlock1}
          setShowBlock={setShowBlock1}
          blockLayout={block1layout}
          blockPhoneLayout={block1Phone}
          rooms={rooms}
          floor={floor}
          setRooms={setRooms}
          selectedUnit={selectedUnit}
          setSelectedUnit={setSelectedUnit}
          selectedFloor={selectedFloor}
          handleAhedRooms={handleAhedRooms}
          windowWidth={windowWidth}
          blockId="block1"
        />

        <BlockComponents
          showBlock={showBlock2}
          setShowBlock={setShowBlock2}
          blockLayout={block2layout}
          blockPhoneLayout={block2layout}
          rooms={rooms}
          floor={floor}
          setRooms={setRooms}
          selectedUnit={selectedUnit}
          setSelectedUnit={setSelectedUnit}
          selectedFloor={selectedFloor}
          handleAhedRooms={handleAhedRooms}
          windowWidth={windowWidth}
          blockId="block2"
        />

        <BlockComponents
          showBlock={showBlock3}
          setShowBlock={setShowBlock3}
          blockLayout={block3layout}
          blockPhoneLayout={block3layout}
          rooms={rooms}
          floor={floor}
          setRooms={setRooms}
          selectedUnit={selectedUnit}
          setSelectedUnit={setSelectedUnit}
          selectedFloor={selectedFloor}
          handleAhedRooms={handleAhedRooms}
          windowWidth={windowWidth}
          blockId="block3"
        />

        {pinData?.map((pin) => (
          <img
            key={pin.id}
            alt={pin.alt}
            onClick={pin.onClick}
            src={pin.src}
            className={`${pin.classes} cursor-pointer lg:hidden bounce absolute ${pin.top}`}
          />
        ))}
        <img
          alt=""
          src={currentLanguageCode === "ar" ? azha4_ar : block4Pin}
          className={` ${
            currentLanguageCode === "ar"
              ? "w-[60px] right-[34%] xl:right-[33%] xl:w-[50px]"
              : "w-[59px] xl:w-[50px] right-[34%] xl:right-[33%]"
          }   lg:hidden  bounce   top-[43%]   absolute`}
        />
        <div className="absolute hidden lg:flex justify-center items-center top-[30%] right-[0px] ">
          <div
            className={`absolute top-[30%] right-[0px] flex items-center transform transition-transform duration-500 ease-in-out ${
              showLocations ? "translate-x-0" : "translate-x-40"
            }`}
          >
            <div
              className="bg-[#202020c2] rounded-full w-[50px] bounce h-[50px] flex justify-center items-center cursor-pointer"
              onClick={() => setShowLocations(!showLocations)} // Toggle state on click
            >
              <img className="w-[25px]" src={pin} alt="pin" />
            </div>

            {/* Animated location names div */}
            <div className="bg-[#202020c2] w-[160px]  h-[150px] rounded-[10px] flex flex-col justify-evenly px-[5%] text-white text-[12px] ml-4">
              <p
                className="underline w-[fit-content]"
                onClick={() => setTownZoom(true)}
              >
                Ahed Villas
              </p>
              <p
                className="underline w-[fit-content]"
                onClick={() => handleImageClick(8)}
              >
                Plaza
              </p>
              <p
                className="underline w-[fit-content]"
                onClick={() => {
                  handleAhedClicks(4);
                  setShowBlock1(true);
                }}
              >
                Azha Apartment Block 1
              </p>
              <p
                className="underline w-[fit-content]"
                onClick={() => {
                  handleAhedClicks(5);
                  setShowBlock2(true);
                }}
              >
                Azha Apartment Block 2
              </p>
              <p
                className="underline w-[fit-content]"
                onClick={() => {
                  handleAhedClicks(6);
                  setShowBlock3(true);
                }}
              >
                Azha Apartment Block 3
              </p>
              <p
                className="underline w-[fit-content]"
                onClick={(e) => setOpenThuraya(true)}
              >
                Althuraya Villas
              </p>
              <p
                className="underline w-[fit-content]"
                onClick={() => handleImageClick(9)}
              >
                Business Center
              </p>
            </div>
          </div>
        </div>

        {townZoom && (
          <div
            onClick={() => setTownZoom(false)}
            className="h-[100%] w-[100%] flex items-center justify-center z-[100] absolute inset-0 backdrop-blur-md bg-white/30 rounded-xl border border-white/20"
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className="h-[100%] w-[60%] xl:w-[70%] md:h-[65%] lg:w-[70%] md:w-[90%] sm:w-[100%]  animate-zoom-in relative"
            >
              <img
                src={townZoomIn}
                alt=""
                className="h-[100%] w-[100%]  shadow-[0_4px_15px_rgba(0,0,0,0.3)]"
              />
              {console.log("pdfffff", pdf)}
              <a
                href={`https://saroojdevelopment.com/laravel/public/api/storage/${pdf}`}
                target="_blank"
                rel="noopener noreferrer"
                className="absolute top-[3%] font-bold text-[25px] animate-bounce lg:text-[12px] md:text-[10px] right-[2%] hover:text-[#a1a1a1] text-white underline underline-offset-2"
              >
                Download our brochure!
              </a>
              {interactiveMap?.map((villa) => (
                <div
                  key={villa.id}
                  onMouseOver={() => setHoveredVilla(villa.id)}
                  onMouseLeave={() => setHoveredVilla(null)}
                  className={`text-[9px] 2xl:text-[7.5px] flex justify-center items-center flex-col text-white absolute w-[3.2vw] xl:w-[3.8vw] lg:w-[5vw] lg:h-[4.9vw] md:h-[5vw] sm:h-[7vw] h-[2.8vw] 2xl:h-[3.3vw] xl:h-[3.6vw] transform opacity-0 transition-opacity duration-300 hover:opacity-100`}
                  style={{
                    backgroundColor:
                      villa.status === "BOOKED"
                        ? "#b6b60eb0"
                        : villa.status === "SOLD"
                        ? "#ff0000c5"
                        : "#008000b9",
                    transform: `rotate(${villa.rotation})`,
                    left: villa.left,
                    top: villa.top,
                  }}
                ></div>
              ))}

              {hoveredVilla &&
                interactiveMap
                  .filter((villa) => villa.id === hoveredVilla)
                  .map((villa) => (
                    <div
                      key={villa.id}
                      className="bg-[#151515] w-[30%]  rounded-[5px] text-white flex flex-col gap-[10px] lg:gap-[5px] text-[13px] 2xl:text-[0.8vw] lg:text-[9.5px] md:text-[8.5px]  xl:text-[1vw] px-[2%] py-[1%] absolute top-[1%] lg:w-[40%] right-[1%] transition-opacity duration-300 hover:opacity-100"
                    >
                      <div className="flex justify-between">
                        <div className="leading-4"></div>
                        <p
                          className={`${
                            villa.status === "BOOKED"
                              ? "bg-[#b6b60e]"
                              : villa.status === "SOLD"
                              ? "bg-[red]"
                              : "bg-[green]"
                          } text-center h-[fit-content] text-[12px] md:text-[8px] w-[fit-content] p-[2%] rounded-[5px]`}
                        >
                          {villa.status}
                        </p>
                      </div>
                      <div className="font-[cairo] flex flex-col gap-[10px] lg:gap-[5px]">
                        <div className=" flex gap-[12px] items-center lg:gap-[5px]">
                          <img
                            className="h-[20px] w-[20px] lg:w-[15px] lg:h-[15px] md:w-[12px] md:h-[12px]"
                            src={house}
                            alt="house-icon"
                          />
                          <h2>
                            Villa #{villa.villa_number} Type {villa.typeNumber}{" "}
                            {villa.type}
                          </h2>
                        </div>
                        <div className=" flex gap-[12px] items-center lg:gap-[5px]">
                          <img
                            className="h-[25px] w-[25px] lg:w-[18px] lg:h-[18px] md:w-[15px] md:h-[15px]"
                            src={plotSize}
                            alt="house-icon"
                          />
                          <p>Plot Size {villa.plot_size_sqm} sqm</p>
                        </div>
                        <div className=" flex gap-[12px] items-center lg:gap-[5px]">
                          <img
                            className="h-[25px] w-[25px] lg:w-[18px] lg:h-[18px] md:w-[15px] md:h-[15px]"
                            src={bedroom}
                            alt="bedroom"
                          />
                          <p>{villa.number_of_rooms}</p>
                        </div>
                        <div className=" flex gap-[12px] items-center lg:gap-[5px]">
                          <img
                            className="h-[25px] w-[25px] lg:w-[18px] lg:h-[18px] md:w-[15px] md:h-[15px]"
                            src={gfa}
                            alt="gfa"
                          />
                          <p>GFA {villa.gfa_sqm} sqm</p>
                        </div>
                        <div className=" flex gap-[12px] items-center lg:gap-[5px]">
                          <img
                            className="h-[25px] w-[25px] lg:w-[18px] lg:h-[18px] md:w-[15px] md:h-[15px]"
                            src={gfaTotal}
                            alt="gfa"
                          />
                          <p>GFA Extendable to {villa.gfa_sqm_with_ph} sqm</p>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        )}

        {openThuraya && (
          <div
            onClick={() => setOpenThuraya(false)}
            className="h-[100%] w-[100%] flex items-center justify-center z-[100] absolute inset-0 backdrop-blur-md bg-white/30 rounded-xl border border-white/20"
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className="h-[65%] w-[80%] xl:w-[90%]  md:w-[fit-content] md:h-[100%]  animate-zoom-in relative"
            >
              <img
                src={thurayaIn}
                alt=""
                className="h-[100%] md:hidden w-[100%]  shadow-[0_4px_15px_rgba(0,0,0,0.3)]"
              />
              <img
                src={thurayaPhone}
                alt=""
                className="h-[100%] hidden w-[100%] md:flex  shadow-[0_4px_15px_rgba(0,0,0,0.3)]"
              />
              {thurayaMap.map((villa) => (
                <div
                  key={villa.id}
                  onMouseOver={() => setHoveredVilla(villa.id)}
                  onMouseLeave={() => setHoveredVilla(null)}
                  className={`text-[9px] 2xl:text-[7.5px] flex justify-center items-center flex-col text-white absolute w-[3vw] xl:w-[3.3vw] md:w-[30px] h-[5vw] xl:h-[5.8vw] transform opacity-0 transition-opacity duration-300 hover:opacity-100`}
                  style={{
                    backgroundColor:
                      villa.status === "BOOKED"
                        ? "#b6b60eb0"
                        : villa.status === "SOLD"
                        ? "#ff0000c5"
                        : "#008000b9",
                    transform: `rotate(${
                      isSmallScreen ? villa.xlrotation : villa.rotation
                    })`,
                    left: isSmallScreen ? villa.xlLeft : villa.left,
                    top: isSmallScreen ? villa.xlTop : villa.top,
                    height: isSmallScreen && villa.xlheight,
                  }}
                ></div>
              ))}

              {hoveredVilla &&
                thurayaMap
                  .filter((villa) => villa.id === hoveredVilla)
                  .map((villa) => (
                    <div
                      key={villa.id}
                      className="bg-[#151515] w-[20%] xl:w-[30%]  rounded-[5px] text-white flex flex-col gap-[10px] lg:gap-[5px] text-[13px] 2xl:text-[0.8vw] lg:text-[9.5px] md:text-[8.5px]  xl:text-[1vw] px-[2%] py-[1%] absolute top-[1%] md:w-[60%] right-[1%] transition-opacity duration-300 hover:opacity-100"
                    >
                      <div className="flex justify-between">
                        <div className="leading-4"></div>
                        <p
                          className={`text-center h-[fit-content] text-[12px] md:text-[8px] w-[fit-content] p-[2%] rounded-[5px]`}
                          style={{
                            backgroundColor:
                              villa.status === "BOOKED"
                                ? "#b6b60eb0"
                                : villa.status === "SOLD"
                                ? "#ff0000c5"
                                : "#008000b9",
                          }}
                        >
                          {villa.status}
                        </p>
                      </div>
                      <div className="font-[cairo] flex flex-col gap-[10px] lg:gap-[5px]">
                        <div className=" flex gap-[12px] items-center lg:gap-[5px]">
                          <img
                            className="h-[20px] w-[20px] lg:w-[15px] lg:h-[15px] md:w-[12px] md:h-[12px]"
                            src={house}
                            alt="house-icon"
                          />
                          <h2>{villa.thurayaName}</h2>
                        </div>
                        <div className=" flex gap-[12px] items-center lg:gap-[5px]">
                          <img
                            className="h-[25px] w-[25px] lg:w-[18px] lg:h-[18px] md:w-[15px] md:h-[15px]"
                            src={plotSize}
                            alt="house-icon"
                          />
                          <p>{villa.plotSize}</p>
                        </div>
                        <div className=" flex gap-[12px] items-center lg:gap-[5px]">
                          <img
                            className="h-[25px] w-[25px] lg:w-[18px] lg:h-[18px] md:w-[15px] md:h-[15px]"
                            src={bedroom}
                            alt="bedroom"
                          />
                          <p>{villa.bedroom}</p>
                        </div>
                        <div className=" flex gap-[12px] items-center lg:gap-[5px]">
                          <img
                            className="h-[25px] w-[25px] lg:w-[18px] lg:h-[18px] md:w-[15px] md:h-[15px]"
                            src={gfa}
                            alt="gfa"
                          />
                          <p>{villa.gfa}</p>
                        </div>
                        <div className=" flex gap-[12px] items-center lg:gap-[5px]">
                          <img
                            className="h-[25px] w-[25px] lg:w-[18px] lg:h-[18px] md:w-[15px] md:h-[15px]"
                            src={gfaTotal}
                            alt="gfa"
                          />
                          <p>{villa.gfaExtendable}</p>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CommunityMap;
