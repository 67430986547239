import sultanLogo from "../images/sultan-haitham-logo-new.png";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { ImWhatsapp } from "react-icons/im";

import { useTranslation } from "react-i18next";
import { fetchHomeVideo } from "../api";
import { useQuery } from "@tanstack/react-query";
function Footer() {
  const { t } = useTranslation();
  const {
    data: fetchSocialMedia,
    error,
    isLoading,
  } = useQuery({
    queryKey: "fetchHomeVideo",
    queryFn: () => fetchHomeVideo(),
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error fetching video</div>;

  return (
    <div className=" bg-[#000000d3] py-[1%] flex lg:flex-col lg:items-center lg:justify-center items-end w-[100%] ">
      <div className="w-[15vw] 2xl:w-[18vw]  lg:w-[200px]">
        <img
          src={sultanLogo}
          className="w-[100%] h-[100%] object-contain"
          alt="sultan-haitham-logo"
        />
      </div>
      <div className=" w-[75%] 2xl:w-[65%] md:w-[100%]  lg:text-[20px] md:text-[18px]   gap-[5px] flex justify-center items-center flex-col text-[25px]  font-[cairo] tracking-widest">
        {fetchSocialMedia?.map((media) => (
          <>
            <div className="flex  justify-center items-center gap-[10%] lg:gap-[1%] md:gap-0">
              <a
                href={media.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:bg-[white] hover:text-[#202020c2] w-[50px] h-[50px] sm:w-[40px] flex justify-center items-center relative cursor-pointer text-white font-[400]  text-center p-[1%] rounded-full"
              >
                <FaInstagram className="w-[50px]  " />
              </a>

              <a
                href={media.youtube}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:bg-[white] hover:text-[#202020c2] w-[50px] h-[50px] sm:w-[40px] flex justify-center items-center relative cursor-pointer text-white font-[400]  text-center p-[1%] rounded-full"
              >
                <FaYoutube className="w-[50px]  " />
              </a>
              <a
                href={`https://wa.me/${media.whatsapp}`}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:bg-[white] hover:text-[#202020c2] w-[50px] h-[50px] sm:w-[40px] flex justify-center items-center relative cursor-pointer text-white font-[400]  text-center p-[1%] rounded-full"
              >
                <ImWhatsapp className="w-[50px]  " />
              </a>

              <a
                href={`tel:${media.phone}`}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:bg-[white] hover:text-[#202020c2] w-[50px] h-[50px] sm:w-[40px] flex justify-center items-center relative cursor-pointer text-white font-[400]  text-center p-[1%] rounded-full"
              >
                <FaPhoneAlt className="w-[50px]  " />
              </a>
            </div>
          </>
        ))}
        <div className="flex  text-[15px] justify-center items-center flex-col text-white">
          <p>
            <a
              href="mailto:info@saroojdevelopment.com"
              className="hover:underline"
            >
              info@saroojdevelopment.com
            </a>
          </p>
          <p className="text-[15px] lg:text-center lg:text-[12px] text-white ">
            {t("footer")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
